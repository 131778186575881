<template lang="pug">
  SidebarWidget.facet(:title="facetTitle || `Map missing: ${name}`", :class="[`facet-${name}`, {'text-danger': !facetTitle, 'is-loading': loading}]")
    ul.facet-options
      li.facet-options-item(v-for="(count, option, index) in options", v-show="index <= 9 || showAllOptions")
        div.form-check.form-check-checkbox(:class="{'is-disabled': loading}")
          label.form-label
            input.form-check-input(type="checkbox", :checked="checked.indexOf(option) !== -1", :disabled="loading", @click="toggleOption(option)", @input="updateValue($event.target.value)")
            div.form-check-custom-input: Icon(icon="check", prefix="fas")
            div.form-check-content {{ option }}
        span {{ count }}
    a.facet-toggle-options.is-grayscale(@click="showAllOptions = !showAllOptions", :class="{'is-showing-all': showAllOptions}", v-show="Object.keys(options).length > 10"): Icon(icon="chevron-down")
</template>

<script>
import SidebarWidget from "@/components/SidebarWidget"

export default {
  watch: {
    value(value) {
      this.checked = value
    }
  },
  components: {
    SidebarWidget
  },
  props: {
    value: Array,
    name: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    checked: [],
    showAllOptions: false
  }),
  computed: {
    facetTitle() {
      const facetTitleMap = {
        depth: "Depth",
        destinations: "Destination",
        libraries: "Library",
        tags: "Subject Area",
        type: "Media Type"
      }

      return facetTitleMap[this.name] || ""
    }
  },
  mounted() {
    this.checked = this.value
  },
  methods: {
    toggleOption(option) {
      let idx = this.checked.indexOf(option)

      if (idx === -1) {
        this.checked.push(option)
      } else {
        this.checked.splice(idx, 1)
      }
    },
    updateValue: function () {
      this.$emit("input", this.checked)
    }
  }
}
</script>

<style>
.facet {
  & + & {
    @apply mt-12;
  }

  &-title {
    @apply text-gray-700;
  }

  &-options {
    @apply mt-4;

    .form-check {
      @apply mb-0;
    }

    &-item {
      @apply flex items-center justify-between;

      & + .facet-options-item {
        @apply mt-3;
      }
    }
  }

  &-toggle-options {
    @apply block text-center transition-colors transition-transform;

    &.is-showing-all {
      transform: rotate(180deg);
    }
  }
}
</style>
